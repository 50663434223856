import React from "react"
import styles from "./error.module.scss"
import Layout from "../Layout"
import Button from "../Button"
import SvgIcon from "../SvgIcon"
import BudText from "../TextBudStage/"
const Error = () => {
  const onClick = () => {
    window.location.replace(`/BudStage/`)
  }

  return (
    <div className={styles.error}>
      <Layout noFooter type="secondary">
        <div className={styles.container}>
          <SvgIcon className={styles.canIcon} size="5.636vw" name="can-icon" />
          <SvgIcon
            className={styles.peaceIcon}
            size="2.695vw"
            name="peace-icon"
          />
          <SvgIcon
            className={styles.starIcon}
            size="7.281vw"
            name="star-icon"
          />
          <SvgIcon
            className={styles.lightningIcon}
            size="3.472vw"
            name="lightning"
          />
          <SvgIcon
            className={styles.discIcon}
            size="10.417vw"
            name="disc-icon"
          />
          <SvgIcon className={styles.textura} name="textura-fondo" />
          <BudText orientation="vertical" className={styles.budText} />
          {/* <div className={styles.videoCard}>
          <div className={styles.testCard}></div>
        </div> */}
          <div className={styles.text}>
            <p>¡UPS!</p>
            <p>ALGO A SALIDO MAL.</p>
            <p>
              Un error inesperado a ocurrido, por favor vuelva a intentarlo en
              un momento.
            </p>
            <Button label="VOLVER A HOME" onClick={onClick} />
          </div>
        </div>
      </Layout>
      <p>BEBER CON MODERACIÓN. PROHIBIDA SU VENTA A MENORES DE 18 AÑOS.</p>
    </div>
  )
}

export default Error
